import React from 'react';
import { Redirect } from '@reach/router';

const NotFound = () => {
  return (
    <Redirect noThrow to="/" />
  );
}

export default NotFound;
